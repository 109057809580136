import React, { useState, useEffect } from "react";
import * as css from "./style.module.scss";

import { Container, Header, Icon } from "../../..";
import EnrollmentSummary from "../enrollment-summary";
import { connect } from "react-redux";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/successful-task.json";
import { Button } from "antd";

const ThankYouRaw = (props) => {
  const [transition, setTransition] = useState(false);
  const [transition1, setTransition1] = useState(false);
  const [transition2, setTransition2] = useState(false);
  const [transition3, setTransition3] = useState(false);

  const [secondsLeft, setSecondsLeft] = useState(6);

  useEffect(() => {
    setInterval(() => setSecondsLeft(secondsLeft - 1), 1000);
    setTimeout(() => setTransition(true), 1000);
    setTimeout(() => setTransition1(true), 1000);
    setTimeout(() => setTransition2(true), 2000);
    setTimeout(() => setTransition3(true), 6000);
  });

  return (
    <Container
      className={`${css.mainContainer} ${transition ? css.transition : ""} }`}
    >
      <Container className={css.header}>
        <Container className={css.logoContainer}>
          <Icon fill="#fff" icon="maq-logo-beta-white" />
        </Container>
      </Container>
      <Container
        className={`${css.contentContainer} ${
          transition1 ? css.transitiona : ""
        }`}
      >
        <Container className={`${css.lottieContainer} `}>
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: "75%", height: "75%" }}
          />
        </Container>
        <Container
          className={`${css.fadeIn} ${transition2 ? css.transitionb : ""}`}
        >
          {transition2 && (
            <Container
              className={`${css.congratulationsText} ${css.transition1}`}
            >
              You're Awesome! Thank you for trusting Apollo!
            </Container>
          )}
          {transition2 && (
            <Container
              className={`${css.confirmApplicationText} ${css.transition2}`}
            >
              Feel free to start a new quote at anytime.
            </Container>
          )}

          {transition3 ? (
            <Container
              className={`${css.confirmApplicationButton} ${css.transition3}`}
            >
              <Button
                onClick={() => {
                  window.location.replace("/");
                }}
                type="primary"
              >
                Start a New Quote
              </Button>
            </Container>
          ) : (
            transition2 && (
              <Container className={css.timer}>
                {" "}
                A new tab will open in {secondsLeft}s ...
              </Container>
            )
          )}
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  quote_id,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouRaw);
