// extracted by mini-css-extract-plugin
export var additionalInfoContainer = "style-module--additional-info-container--oGBMX";
export var additionalInfoIcon = "style-module--additional-info-icon--TfffE";
export var additionalInfoIconAndTitle = "style-module--additional-info-icon-and-title--SvTZ0";
export var additionalInfoTitle = "style-module--additional-info-title--lp10D";
export var addressInputs = "style-module--address-inputs--36UaZ";
export var addressNumberInput = "style-module--address-number-input--yIKW1";
export var addressStreetNameInput = "style-module--address-street-name-input--weP8O";
export var agreeButton = "style-module--agree-button--IG4DI";
export var applicantContainer = "style-module--applicant-container--SuMfQ";
export var applicantIcon = "style-module--applicant-icon--y9Hrk";
export var applicantTitle = "style-module--applicant-title--6Q5-3";
export var birthdateContainer = "style-module--birthdate-container--315f7";
export var buttonsContainer = "style-module--buttons-container--vA4Ao";
export var coverageContainer = "style-module--coverage-container--r-qQ6";
export var coverageDateInput = "style-module--coverage-date-input--BJD3H";
export var coverageDates = "style-module--coverage-dates--1BGg7";
export var datePicker = "style-module--date-picker--tqA0j";
export var firstNameInputContainer = "style-module--first-name-input-container--usEXZ";
export var householdContainer = "style-module--household-container--QfkZX";
export var householdIcon = "style-module--household-icon--h+QP1";
export var householdIconAndTitle = "style-module--household-icon-and-title--EMuUp";
export var householdTitle = "style-module--household-title--8JAYS";
export var inputs = "style-module--inputs--TfE8Q";
export var lastInputs = "style-module--last-inputs--qjzLg";
export var lastNameInputContainer = "style-module--last-name-input-container--KGuDG";
export var nextButton = "style-module--next-button--lc4-2";
export var previousButton = "style-module--previous-button---fYXU";
export var priceDisclaimer = "style-module--price-disclaimer--rozwK";
export var streetAndNumberContainer = "style-module--street-and-number-container--sVS2X";
export var weightNotAllowed = "style-module--weight-not-allowed--EKvPU";
export var weightNotAllowedContainer = "style-module--weight-not-allowed-container--rZc6N";
export var weightNotAllowedIcon = "style-module--weight-not-allowed-icon--If2um";
export var weightNotAllowedIconAndText = "style-module--weight-not-allowed-icon-and-text--BWi7z";
export var weightNotAllowedText = "style-module--weight-not-allowed-text--dp9-M";