// extracted by mini-css-extract-plugin
export var confirmApplicationButton = "style-module--confirm-application-button--saFxu";
export var confirmApplicationText = "style-module--confirm-application-text--Jk1my";
export var congratulationsText = "style-module--congratulations-text--JmJra";
export var contentContainer = "style-module--content-container--JfXGv";
export var emailSentText = "style-module--email-sent-text--PFq++";
export var fadeIn = "style-module--fade-in--Kgw0i";
export var header = "style-module--header--5mjGh";
export var logoContainer = "style-module--logo-container--YDLlk";
export var lottieContainer = "style-module--lottie-container--f2bGi";
export var mainContainer = "style-module--main-container--RL9jU";
export var step = "style-module--step--0vYw6";
export var stepsContainer = "style-module--steps-container--A0l6V";
export var timer = "style-module--timer--rAav-";
export var transition = "style-module--transition--Lr5AR";
export var transitiona = "style-module--transitiona--L+vn7";
export var transitionb = "style-module--transitionb--QIPYL";