import React from "react";

import { Container } from "../../../";
import { v4 as uuidv4 } from "uuid";
import * as css from "./style.module.scss";
import { Button, Radio, Input, Space } from "antd";
import { connect } from "react-redux";

import Toastify from "toastify-js";

const EligibiltyQuestionsRaw = (props) => {
  const [isEligibilityToastOpen, setIsEligibilityToastOpen] =
    React.useState(false);

  const answerQuesion = (v, q, index) => {
    props.answerEligibilibityQuestion({
      ...q,
      index,
      answer: q.possibleAnswers[Number(!v.target.value)],
    });

    if (
      v.target.checked &&
      q.possibleAnswers[Number(!v.target.value)].isKnockOut
    ) {
      if (isEligibilityToastOpen) return;
      Toastify({
        text: q.possibleAnswers[Number(!v.target.checked)].errorMessage,
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "bottom",
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
        callback: () => setIsEligibilityToastOpen(false),
      }).showToast();
      setIsEligibilityToastOpen(true);
    }
  };

  const hasAllstateOriginalPolicyLessThanTwelveMonths =
    props.applications[0]?.eligibilityQuestions?.some(
      (question) =>
        question?.questionId === 2801 && question?.answer?.answerText === "Yes"
    );

  const hasShortTermPlans = props?.plans?.some(
    (plan) => plan?.category === "SHORT_TERM"
  );

  return (
    <Container>
      {hasShortTermPlans && (
        <Container className={css.aboutContainer}>
          <Container className={css.disclaimerContainer}>
            <h1 className={css.eligibilityQuestionsTitle}>
              IMPORTANT: This is a short-term, limited-duration policy, NOT
              comprehensive health coverage
            </h1>
            <div>
              <p>
                This is a temporary limited policy that has fewer benefits and
                Federal protections than other types of health insurance
                options, like those on HealthCare.gov.
              </p>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>This policy</th>
                    <th>Insurance on HealthCare.gov</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Might not cover</b> you due to preexisting health
                      conditions like diabetes, cancer, stroke, arthritis, heart
                      disease, mental health & substance use disorders
                    </td>
                    <td>
                      Can&apos;t deny you coverage due to preexisting health
                      conditions
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Might not cover</b> things like prescription drugs,
                      preventive screenings, maternity care, emergency services,
                      hospitalization, pediatric care, physical therapy & more
                    </td>
                    <td>Covers all essential health benefits</td>
                  </tr>
                  <tr>
                    <td>
                      Might have <b>no limit on what you pay</b> out-of-pocket
                      for care
                    </td>
                    <td>
                      Protects you with limits on what you pay each year
                      out-of-pocket for essential health benefits
                    </td>
                  </tr>
                  <tr>
                    <td>
                      You <b>won&apos;t qualify</b> for Federal financial help
                      to pay premiums & out-of-pocket costs
                    </td>
                    <td>Many people qualify for Federal financial help</td>
                  </tr>
                  <tr>
                    <td>
                      Doesn&apos;t have to meet Federal standards for
                      comprehensive health coverage
                    </td>
                    <td>All plans must meet Federal standards</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <p>
                <b>Looking for comprehensive health insurance?</b>
              </p>
            </div>
            <div>
              <p>
                <b>Visit HealthCare.gov</b> or call <b>1-800-318-2596</b> (TTY:
                1-855-889-4325) to find health coverage options.
              </p>
            </div>
            <div>
              <p>
                To find out if you can get health insurance through your job, or
                a family member&apos;s job, contact the employer.
              </p>
            </div>
            <div>
              <p>
                <b>Questions about this policy?</b>
              </p>
            </div>
            <div>
              <p>
                For questions or complaints about this policy, contact your
                State Department of Insurance. Find their number on the National
                Association of Insurance Commissioners&apos; website (naic.org)
                under “Insurance Departments.”
              </p>
            </div>
          </Container>
        </Container>
      )}

      {props.applications.map((application, i) => {
        if (application.eligibilityQuestions.length == 0) return null;

        const questions = application.eligibilityQuestions.map((question) => {
          question.possibleAnswers.forEach((q) => {
            if (!["Yes", "No"].includes(q.answerText)) {
              throw new Error("UNEXPECTED QUESTION FORMAT");
            }
          });

          return {
            id: uuidv4(),
            application_index: i,
            question: question.questionText,
            possibleAnswers: question.possibleAnswers,
            answer: question.answer,
            errorMessage: question.errorMessage,
            questionId: question.questionId,
          };
        });

        return (
          <Container
            key={i + "elibibility-key"}
            className={css.eligibilityQuestionsContainer}
          >
            <Container className={css.eligibilityQuestionsInnerContainer}>
              <Container
                className={`${css.eligibilityQuestionsTitle} ${
                  questions.some((q) => q.answer && q.answer.isKnockOut)
                    ? css.blocked
                    : questions.every((q) => q.answer)
                    ? css.allowed
                    : ""
                }`}
              >
                {application.eligibilityTitle}
              </Container>
              <Container className={css.eligibilityQuestions}>
                {questions.map((q, index) => {
                  let canRenderQuestion = true;

                  if (q.questionId === 2802) {
                    if (!hasAllstateOriginalPolicyLessThanTwelveMonths) {
                      canRenderQuestion = false;
                    }
                  }

                  if (!canRenderQuestion) {
                    return null;
                  }

                  return (
                    <Container className={css.eligibilityQuestion} key={q.id}>
                      <Container style={{ display: "flex" }}>
                        <Container className={css.eligibilityQuestionIndex}>
                          {index + 1}.
                        </Container>
                        <Container
                          className={`${css.eligibilityQuestionText} ${
                            q.answer
                              ? q.answer.isKnockOut
                                ? css.eligibilityQuestionError
                                : css.eligibilityQuestionOk
                              : ""
                          } `}
                        >
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{ __html: q.question }}
                          />{" "}
                        </Container>
                      </Container>
                      <Container
                        className={`${css.eligibilityQuestionRadio} hidden-on-mobile`}
                      >
                        <Radio.Group
                          value={
                            q.answer
                              ? q.answer.answerText == "Yes"
                                ? 1
                                : 0
                              : undefined
                          }
                          onChange={(v) => {
                            answerQuesion(v, q, index);
                          }}
                          buttonStyle="solid"
                          size="large"
                        >
                          <Space direction="vertical">
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </Container>
                      <Container
                        className={`${css.eligibilityQuestionRadio} hidden-on-web`}
                      >
                        <Radio.Group
                          value={
                            q.answer
                              ? q.answer.answerText == "Yes"
                                ? 1
                                : 0
                              : undefined
                          }
                          onChange={(v) => {
                            answerQuesion(v, q, index);
                          }}
                          buttonStyle="solid"
                          size="large"
                        >
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Container>
                    </Container>
                  );
                })}
              </Container>
            </Container>
          </Container>
        );
      })}

      <Container className={css.nextButton}>
        <Button
          onClick={() => {
            props.next && props.next();
          }}
          disabled={props.applications.some((a) =>
            a.eligibilityQuestions.some((q) => {
              if (!hasAllstateOriginalPolicyLessThanTwelveMonths) {
                return q.questionId === 2802;
              }

              return !q.answer || (q.answer && q.answer.isKnockOut);
            })
          )}
          type="primary"
          shape="round"
          name="test-enrollment-enrollment-tools-eligibility-button-next"
        >
          Next
        </Button>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({ enrollment }) => {
  return {
    applications: [...enrollment.nationalGeneral.applications],
    plans: enrollment.nationalGeneral.plans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    answerEligibilibityQuestion: (payload) =>
      dispatch({ type: `ANSWER_ELIGIBILITY_QUESTION`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EligibiltyQuestionsRaw);
