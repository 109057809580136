import React, { useState, useEffect } from "react";

import { Container, Icon, TextInput, Select } from "../../../";

import { OfferCard } from "../../../quotes-panel/components";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button, Dropdown, Menu } from "antd";
import { connect } from "react-redux";

import CreditCards from "./credit-cards";

import axios from "axios";

import moment from "moment";

import { handleInputChange } from "../../../../utils";

import {
  rountingNumberInputHandler,
  ccNumberInputInputHandler,
} from "../../../../utils";

import Toastify from "toastify-js";

const Divider = () => <Container className={css.divider} />;

const CheckoutRaw = (props) => {
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [exp, setExp] = useState();
  const [cvc, setCvc] = useState();
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [accountType, setAccountType] = useState();
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [isToastErrorOpened, setIsToastErrorOpened] = useState(false);
  const params = JSON.parse(localStorage.getItem("params"));
  const spouse = props.applicants.spouse;
  const monthly = JSON.parse(localStorage.getItem("plans")).reduce((a, b) => {
    return a + Number(b.plan_only_premium.value);
  }, props.enrollment.nationalGeneral.feesRaw["MONTHLY"].total);

  const enrollmentFee =
    props.enrollment.nationalGeneral.fees.filter(
      (f) => f.label == "One time fees"
    ).length == 1
      ? props.enrollment.nationalGeneral.fees.filter(
          (f) => f.label == "One time fees"
        )[0].value
      : 0;

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1200)).then(() => {
      setLoading(false);
    });
  }, []);

  const isDisabled =
    paymentMethod === "Credit Card"
      ? !firstName ||
        !lastName ||
        !cardNumber ||
        !new RegExp("(0[1-9]|1[0-2])(/)(2[0-9]{3})").test(exp) ||
        cvc?.length <= 2
      : !firstName ||
        !lastName ||
        !accountType ||
        accountNumber?.length < 6 ||
        routingNumber?.length !== 11;

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.checkoutContainer}>
      <Container className={css.paymentInfoIconAndTitle}>
        <Container className={css.paymentInfoIcon}>
          <Icon icon="shopping-cart-circle" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          <Container>Payment Information:</Container>
          <Container className={css.paymentDisclaimer}>
            A payment of ${(monthly + enrollmentFee).toFixed(2)} will be
            processed in the submission of your application. It includes monthly
            prices plus any enrollment fees.
          </Container>
        </Container>
      </Container>

      <Container className={css.inputs}>
        <Container>
          <Container className={css.paymentInfosContainer}>
            <Container className={css.paymentInfoContainer}>
              <Container className={css.paymentIcon}>
                <Icon fill="#60cc73" icon="due-today" />
              </Container>
              <Container className={css.paymentRadioButtonTexts}>
                <Container className={css.paymentRadioButtonTitle}>
                  First Payment
                </Container>
                <Container className={css.paymentRadioButtonText}></Container>
              </Container>
              <Container className={css.paymentRadioButtonValue}>
                ${(monthly + enrollmentFee).toFixed(2)}
              </Container>
            </Container>
            <Container className={css.upcomingPaymentsInfoContainer}>
              <Container className={css.upcomingPaymentsIcon}>
                <Icon fill="#89D8F0" icon="upcoming-payments" />
              </Container>
              <Container className={css.upcomingPaymentsTexts}>
                <Container className={css.upcomingPaymentsTitle}>
                  Monthly Payments
                </Container>
                <Container className={css.upcomingPaymentsText}></Container>
              </Container>
              <Container className={css.upcomingPaymentsValue}>
                ${monthly.toFixed(2)}
              </Container>
            </Container>
          </Container>
        </Container>
        <Divider />
        <Container className={css.cardInputs}>
          <Container>
            <Container className={css.paymentMethodContainer}>
              <Container className={css.paymentMethodSelectContainer}>
                <Select
                  value={paymentMethod}
                  size="large"
                  before="Payment Method"
                  onChange={(e) => {
                    setPaymentMethod(e);
                  }}
                  options={[
                    {
                      label: "Bank/ACH Payment",
                      value: "Bank/ACH Payment",
                    },
                    {
                      label: "Credit Card",
                      value: "Credit Card",
                    },
                  ]}
                />
              </Container>
              <Container className={css.creditCards}>
                {paymentMethod == "Credit Card" && <CreditCards />}
                {paymentMethod != "Credit Card" && (
                  <Container
                    className={css.upcomingPaymentsInfoContainer}
                    style={{
                      transform: "translate(-48px, -6px",
                      height: "59px",
                    }}
                  >
                    <Container
                      style={{ marginRight: "24px" }}
                      className={css.n}
                    >
                      <Icon
                        style={{
                          width: "36px",
                          height: "36px",
                          transform: "translateY(2px",
                        }}
                        fill="#77c3ff"
                        icon="info-shield"
                      />
                    </Container>
                    <Container className={css.upcomingPaymentsTexts}>
                      <Container className={css.upcomingPaymentsText}>
                        Your first payment will be securely debited from your
                        bank account with the submission of your application
                      </Container>
                    </Container>
                  </Container>
                )}
              </Container>
            </Container>
          </Container>
          {paymentMethod == "Credit Card" && (
            <>
              <Container className={css.paymentInfoIconAndTitle}>
                <Container className={css.paymentInfoIcon}>
                  <Icon icon="credit-card-circle" />
                </Container>
                <Container className={css.additionalInfoTitle}>
                  <Container>Credit Card info:</Container>
                  <Container className={css.paymentDisclaimer}>
                    Please fill up the next fields exactely as in your credit
                    card
                  </Container>
                </Container>
              </Container>
              <Container>
                <Container className={css.cardNames}>
                  <Container className={`${css.cardName} ${css.firstName}`}>
                    <TextInput
                      onChange={(e) => {
                        setFirstName(handleInputChange(e));
                      }}
                      value={firstName}
                      before="First Name"
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                  <Container className={`${css.cardName} ${css.lastName}`}>
                    <TextInput
                      onChange={(e) => {
                        setLastName(handleInputChange(e));
                      }}
                      value={lastName}
                      before="Last Name"
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                </Container>
              </Container>

              <Container className={css.cardNumbers}>
                <Container className={css.cardNumber}>
                  <TextInput
                    onChange={(e) => {
                      if (e.target.value.length > 19) return e.target.value;

                      const cc = ccNumberInputInputHandler(e);
                      setCardNumber(cc);
                    }}
                    value={cardNumber}
                    before="Card Number"
                  />
                </Container>

                <Container className={css.cardDetails}>
                  <Container className={css.exp}>
                    <TextInput
                      onChange={(e) => {
                        if (e.target.value.length > 7) return;

                        const divider =
                          e.target.value.length == 2 &&
                          e.target.value.length > exp?.length
                            ? "/"
                            : "";

                        setExp(handleInputChange(e) + divider);
                      }}
                      value={exp}
                      style={{ paddingLeft: "10px" }}
                      mask={new RegExp("(0[1-9]|1[0-2])(/)(2[0-9]{3})")}
                      placeholder="Expiration Date"
                      beforeWidth="60px"
                      focusedlabel={"MM/YYYY"}
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                  <Container className={css.cvc}>
                    <TextInput
                      value={cvc}
                      onChange={(e) => {
                        if (e.target.value > 9999) return;
                        setCvc(handleInputChange(e));
                      }}
                      mask={new RegExp("([0-9]{3}[0-9]?)")}
                      type="number"
                      placeholder="CVC"
                    />
                  </Container>
                </Container>
              </Container>
            </>
          )}
          {paymentMethod != "Credit Card" && (
            <>
              <Container className={css.paymentInfoIconAndTitle}>
                <Container className={css.paymentInfoIcon}>
                  <Icon icon="bank-acc" />
                </Container>
                <Container className={css.additionalInfoTitle}>
                  <Container>Banking account info:</Container>
                  <Container className={css.paymentDisclaimer}>
                    Fields should be in accord to the banking account that will
                    be used for your payments.
                  </Container>
                </Container>
              </Container>
              <Container>
                <Container className={css.cardNames}>
                  <Container className={`${css.cardName} ${css.firstName} `}>
                    <TextInput
                      onChange={(e) => {
                        setFirstName(handleInputChange(e));
                      }}
                      value={firstName}
                      before="First Name"
                    />
                  </Container>
                  <Container className={`${css.cardName} ${css.lastName}`}>
                    <TextInput
                      onChange={(e) => {
                        setLastName(handleInputChange(e));
                      }}
                      value={lastName}
                      before="Last Name"
                    />
                  </Container>
                </Container>
              </Container>

              <Container className={css.cardNames}>
                <Container className={`${css.cardName} ${css.firstName} `}>
                  <Select
                    value={accountType}
                    size="large"
                    before="Account type"
                    onChange={(e) => {
                      setAccountType(e);
                    }}
                    options={[
                      {
                        label: "Checking",
                        value: "Checking",
                      },
                      {
                        label: "Savings",
                        value: "Savings",
                      },
                    ]}
                  />
                </Container>
                <Container className={`${css.cardName} ${css.lastName} `}>
                  <TextInput
                    onChange={(e) => {
                      setBankName(handleInputChange(e));
                    }}
                    value={bankName}
                    before="Bank Name"
                  />
                </Container>
              </Container>

              <Container className={css.cardNames}>
                <Container className={`${css.cardName} ${css.firstName} `}>
                  <TextInput
                    onChange={(e) => {
                      setAccountNumber(handleInputChange(e));
                    }}
                    value={accountNumber}
                    before="Account Number"
                    mask={new RegExp("[0-9]{6,16}")}
                  />
                </Container>
                <Container className={`${css.cardName} ${css.lastName}`}>
                  <TextInput
                    value={routingNumber}
                    onChange={(e) =>
                      setRoutingNumber(rountingNumberInputHandler(e))
                    }
                    before="Routing Number"
                    mask={new RegExp("[0-9]{3} [0-9]{3} [0-9]{3}")}
                  />
                </Container>
              </Container>
            </>
          )}
        </Container>
      </Container>
      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            onClick={async () => {
              setLoading(true);
              try {
                let specialRates = {};
                let selected_term = JSON.parse(
                  localStorage.getItem("additional_info") || "{}"
                )?.NATIONAL_GENERAL?.default_option;

                const priorCoverage = sessionStorage.getItem("priorCoverage");
                const rateTier = sessionStorage.getItem("rateTier");
                const selectedTermData =
                  sessionStorage.getItem("allstateTermData");

                if (selectedTermData) {
                  selected_term = JSON.parse(selectedTermData);
                }

                if (priorCoverage) {
                  specialRates = {
                    has_prior_coverage: priorCoverage == "true" ? true : false,
                  };
                }

                if (rateTier) {
                  specialRates = {
                    ...specialRates,
                    rate_tier: rateTier,
                  };
                }

                const resp = await axios({
                  method: "post",
                  url: `${props.baseUrl}/v1/enrollment/national-general`,
                  data: {
                    ...{
                      quote_id: params.quote_id,
                      primary_applicant: {
                        ...(specialRates &&
                        specialRates?.rate_tier == "Preferred"
                          ? {
                              preferred_questions:
                                props.enrollment.nationalGeneral.applications.reduce(
                                  (a, b) => {
                                    b.generalQuestions.forEach((q) => {
                                      if (q.questionId == 765) {
                                        a.push({
                                          question_id: q.questionId,
                                          response: "Yes",
                                        });
                                      } else {
                                        a.push({
                                          question_id: q.questionId,
                                          response: "No",
                                        });
                                      }
                                    });
                                    return a;
                                  },
                                  []
                                ),
                            }
                          : {}),
                        first_name: params.firstName || "John",
                        last_name: params.lastName || "Doe",
                        gender: params.gender,
                        phone: params.phone || "2121234567",
                        date_of_birth:
                          typeof props.date_of_birth == "string"
                            ? props.date_of_birth
                            : props.date_of_birth
                                .toDate()
                                .toISOString()
                                .split("T")[0],
                        uses_tobacco: params.uses_tobacco,
                        ...(props.weight
                          ? { weight_in_lbs: props.weight }
                          : {}),
                        ...(props.feet
                          ? {
                              height: {
                                feet: props.feet,
                                inches: props.inches || 0,
                              },
                            }
                          : {}),
                        email: params.email || "test@apollo-insurance.com",
                        ...(Object.keys(specialRates).length > 0
                          ? specialRates
                          : {}),
                      },
                      spouse: spouse
                        ? {
                            ...(specialRates &&
                            specialRates?.rate_tier == "Preferred"
                              ? {
                                  preferred_questions:
                                    props.enrollment.nationalGeneral.applications.reduce(
                                      (a, b) => {
                                        b.generalQuestions.forEach((q) => {
                                          if (q.questionId == 765) {
                                            a.push({
                                              question_id: q.questionId,
                                              response: "Yes",
                                            });
                                          } else {
                                            a.push({
                                              question_id: q.questionId,
                                              response: "No",
                                            });
                                          }
                                        });
                                        return a;
                                      },
                                      []
                                    ),
                                }
                              : {}),
                            first_name: props.spouse.first_name,
                            last_name: props.spouse.last_name,
                            gender: JSON.parse(JSON.parse(params.spouse))
                              .gender,
                            date_of_birth:
                              typeof props.spouse.date_of_birth == "string"
                                ? props.spouse.date_of_birth
                                : props.spouse.date_of_birth
                                    .toDate()
                                    .toISOString()
                                    .split("T")[0],
                            uses_tobacco: props.spouse.uses_tobacco || false,
                            weight_in_lbs: props.spouse.weight,
                            height: {
                              feet: props.spouse.feet,
                              inches: props.spouse.inches || 0,
                            },
                            ...(Object.keys(specialRates).length > 0
                              ? specialRates
                              : {}),
                          }
                        : undefined,
                      dependents: props.dependents
                        ? props.dependents.map((d, ind) => {
                            return {
                              first_name: d.first_name,
                              last_name: d.last_name,
                              gender: d.gender || "Female",
                              date_of_birth:
                                typeof d.date_of_birth == "string"
                                  ? d.date_of_birth
                                  : d.date_of_birth
                                      .toDate()
                                      .toISOString()
                                      .split("T")[0],
                              uses_tobacco: d.uses_tobacco || false,
                              ...(Object.keys(specialRates).length > 0
                                ? specialRates
                                : {}),
                            };
                          })
                        : [],
                      address: {
                        city: props.city || "Thomasville",
                        street_address: props.address || "815 Tennessee Ave",
                      },
                      payment:
                        paymentMethod != "Credit Card"
                          ? {
                              account_holder_first_name: firstName,
                              account_holder_last_name: lastName,
                              bank_name: bankName, // Chase Bank
                              routing_number: routingNumber, //044000037
                              account_number: accountNumber, // 1234567890
                              account_type: accountType || "Checking",
                              ...(props.firstPaymentDate
                                ? {
                                    first_payment_date: props.firstPaymentDate
                                      .toDate()
                                      .toISOString()
                                      .split("T")[0],
                                  }
                                : {}),
                            }
                          : {
                              first_name: firstName || "John",
                              last_name: lastName || "Doe",
                              credit_card_number:
                                cardNumber || "4112 3441 1234 4113",
                              expiration_date: exp || "12/2025",
                              cvc: cvc + "" || "123",
                              ...(props.firstPaymentDate
                                ? {
                                    first_payment_date: props.firstPaymentDate
                                      .toDate()
                                      .toISOString()
                                      .split("T")[0],
                                  }
                                : {}),
                            },
                      selected_plans: JSON.parse(
                        localStorage.getItem("plans")
                      ).map((p) => {
                        return {
                          plan_key: p.plan_key.value,
                          plan_id: p.plan_id.value + "",
                          effective_date: (
                            props.effectiveDate || moment().add(1, "days")
                          )
                            .toDate()
                            .toISOString()
                            .split("T")[0],
                          monthly_premium: p.plan_only_premium.value,
                          insurance_network: p.insurance_network.value,
                          general_info: p.general_info,
                          // ...p.info.value == "Short Term" ? { terms_in_months: props.terms,} : { }
                        };
                      }),
                      fees: props.enrollment.nationalGeneral.feesRaw,
                      questions_responses: [],
                      selected_term,
                      npn: params.npn || "220439",
                    },
                  },
                  headers: {
                    "Apollo-Quotes-Source": props.agentMetadata.id,
                  },
                });
                setLoading(false);

                if (Number(resp.status) == 200) {
                  props.setAdditionalInfo({
                    key: "userEmail",
                    value: params.email,
                  });
                  props.setAdditionalInfo({
                    key: "linkToEnrollment",
                    value: resp.data.memberPortalUrl,
                  });
                  props.setCurrentStep(3);
                } else {
                  if (isToastErrorOpened) return;
                  Toastify({
                    text: resp.data.detail,
                    duration: 10000,
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "center",
                    backgroundColor: "#D33F49",
                    stopOnFocus: true,
                    className: "notification",
                    callback: () => setIsToastErrorOpened(false),
                  }).showToast();
                }
                setIsToastErrorOpened(true);
              } catch (ex) {
                console.log(ex);
                setLoading(false);
                if (isToastErrorOpened) return;
                Toastify({
                  text: ex.response
                    ? ex.response.data.detail
                    : "We are sorry, we couldn't complete your application, please try again in a few minutes.",
                  duration: 10000,
                  newWindow: true,
                  close: true,
                  gravity: "top",
                  position: "center",
                  backgroundColor: "#D33F49",
                  stopOnFocus: true,
                  className: "notification",
                  callback: () => setIsToastErrorOpened(false),
                }).showToast();
                setIsToastErrorOpened(true);
              }
            }}
            type="primary"
            shape="round"
            disabled={isDisabled}
          >
            Confirm Payment
          </Button>
        </Container>
      </Container>
      <Container className={css.disclaimerContainer}>
        The accountholder of the bank account of credit card provider during
        this enrollment process and requests the insurer to initiate automatic
        electronic payments against such indicated bank <br /> account of credit
        card for the payment of premiuns and other indicated monthly dues
        included in the plan(s) being purchased during this enrollment process.
        Accountholder agrees that the <br />
        electronic payment authorization for such automatic payments may be
        terminated by providing written notice to the insurer.
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  quote_id,
  enrollment,
  applicants,
  specialRates,
}) => {
  return {
    applicants,
    baseUrl,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
    date_of_birth: enrollment.nationalGeneral.additionalInfo.date_of_birth
      ? enrollment.nationalGeneral.additionalInfo.date_of_birth
      : applicants.date_of_birth,
    specialRates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
    confirmApplication: (payload) =>
      dispatch({ type: `SET_APPLICATION_URL`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutRaw);
