import React, { useState } from "react";
import * as css from "./style.module.scss";

import { Container, Header, Icon } from "../../..";
import EnrollmentSummary from "../enrollment-summary";
import { Steps } from "antd";

const { Step } = Steps;

const MasterPage = ({ children, currentStep, setCurrentStep, data }) => {
  const steps = [
    {
      title: <Container className={css.step}>Eligibility</Container>,
    },
    {
      title: <Container className={css.step}>Additional Info</Container>,
    },
    {
      title: <Container className={css.step}>Checkout</Container>,
    },
  ];

  return (
    <Container className={css.mainContainer}>
      <Container className={css.header}>
        <Container className={css.logoContainer}>
          <Icon raw fill="#fff" icon="maq-logo-beta" />
        </Container>

        <Container className={css.stepsContainer}>
          <Steps
            size="small"
            className={css.steps}
            current={currentStep}
            type="navigation"
            onChange={(e) => {
              setCurrentStep(e);
            }}
          >
            {steps.map((item, pos) => (
              <Step
                key={"step" + pos + "ane"}
                title={item.title}
                disabled={pos > currentStep}
              />
            ))}
          </Steps>
        </Container>
      </Container>
      <Container className={css.summaryContainer}>
        <EnrollmentSummary />
      </Container>
      <Container className={css.contentContainer}>{children}</Container>
      <Container className={css.footerContainer}>
        Aspire Insurance Solutions | MyAspireQuotes ® - 2022 All Rights Reserved
        <Icon
          className={css.logoWhite}
          fill="#fff"
          icon="my-aspire-quotes-white-beta"
        />
      </Container>
    </Container>
  );
};

export default MasterPage;
