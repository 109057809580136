import React, { useState, useEffect, isValidElement, useMemo } from "react";
import Toastify from "toastify-js";

import {
  Container,
  ConversionSlider,
  DatePicker,
  DoubledInputSlider,
  Icon,
  InputSlider,
  TextInput,
  Select,
} from "../../../";

import { OfferCard } from "../../../quotes-panel/components";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button } from "antd";
import { connect } from "react-redux";

import moment from "moment";
import axios from "axios";
import { handleInputChange } from "../../../../utils";
import { formatCurrency } from "../../../../utils";

import _ from "lodash";

const AdditionalInfoRaw = (props) => {
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const effectiveDate = props.effectiveDate;
  const firstPaymentDate = props.firstPaymentDate;
  const [updatedDependentsDateOfBirth, setUpdatedDependentsDateOfBirth] =
    useState(false);

  const specialRates = props.specialRates || {};

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
      setLoading(false);
    });
  }, []);
  const params = JSON.parse(localStorage.getItem("params"));
  const additionalInfo = JSON.parse(localStorage.getItem("additional_info"));

  const isChildOnlyQuote = params?.child_only_plans === "true";

  params.dependents = params.dependents ? JSON.parse(params.dependents) : [];
  const spouse = props.applicants.spouse;
  const dependents = props.applicants.dependents;
  const effectiveDateLimit =
    additionalInfo?.NATIONAL_GENERAL?.effective_date_limit || 45;

  React.useEffect(() => {
    if (
      effectiveDate === undefined ||
      props?.date_of_birth === undefined ||
      (spouse ? props?.spouse?.date_of_birth === undefined : false) ||
      (props.applicants.dependents.length > 0
        ? props.applicants.dependents.some((d) => d.date_of_birth === undefined)
        : false)
    )
      return;
    getUpdatedValues();
  }, [
    updatedDependentsDateOfBirth,
    props?.spouse?.date_of_birth,
    props?.date_of_birth,
    effectiveDate,
  ]);

  const getUpdatedValues = async () => {
    if (effectiveDate === undefined || !firstPaymentDate) return;

    try {
      let specialRates = {};
      let selected_term = JSON.parse(
        localStorage.getItem("additional_info") || "{}"
      )?.NATIONAL_GENERAL?.default_option;

      const priorCoverage = sessionStorage.getItem("priorCoverage");
      const rateTier = sessionStorage.getItem("rateTier");
      const selectedTermData = sessionStorage.getItem("allstateTermData");

      if (selectedTermData) {
        selected_term = JSON.parse(selectedTermData);
      }

      if (priorCoverage) {
        specialRates = {
          has_prior_coverage: priorCoverage == "true" ? true : false,
        };
      }

      if (rateTier) {
        specialRates = {
          ...specialRates,
          rate_tier: rateTier,
        };
      }

      const response2 = await axios({
        method: "post",
        url: `${props.baseUrl}/v1/enrollment/national-general/${params.quote_id}/accurate-quote`,
        data: {
          selected_term,
          zip_code: params.zip_code,
          state: params.state,
          effective_date: effectiveDate.toDate().toISOString().split("T")[0],
          primary_applicant: {
            gender: params.gender,
            date_of_birth:
              typeof props.date_of_birth === "string"
                ? props.date_of_birth
                : props.date_of_birth.toDate().toISOString().split("T")[0],
            uses_tobacco: params.uses_tobacco,
            ...(Object.keys(specialRates).length > 0 ? specialRates : {}),
          },
          spouse: props.applicants.spouse
            ? {
                ...props.spouse,
                date_of_birth:
                  typeof props.spouse.date_of_birth === "string"
                    ? props.spouse.date_of_birth
                    : props.spouse.date_of_birth
                        .toDate()
                        .toISOString()
                        .split("T")[0],
                gender: spouse.gender,
                uses_tobacco: spouse.uses_tobacco ? true : false,
                ...(Object.keys(specialRates).length > 0 ? specialRates : {}),
              }
            : null,
          dependents: dependents
            ? dependents.map((d) => {
                return {
                  ...d,
                  date_of_birth:
                    typeof d.date_of_birth == "string"
                      ? d.date_of_birth
                      : d.date_of_birth.toISOString().split("T")[0],
                  ...(Object.keys(specialRates).length > 0 ? specialRates : {}),
                };
              })
            : [],
          plans: JSON.parse(params.plans).map((p) => {
            return {
              plan_key: p.plan_key.value,
              insurance_network: p.insurance_network.value,
              fees_enabled: params.optionalAssociationFeeEnabled,
              secondary_plan_id: p.general_info.secondary_plan_id,
            };
          }),
        },
        headers: {
          "x-apollo-quotes-source": props.agentMetadata.id,
        },
      });

      let parsedPlans = response2.data.plans;

      if (response2.data.discount) {
        parsedPlans = parsedPlans.map((plan) => {
          if (plan.plan_id.value === response2.data.discount.plan_id) {
            return {
              ...plan,
              discount: response2.data.discount,
            };
          }
          return plan;
        });
      }

      localStorage.setItem("plans", JSON.stringify(parsedPlans));

      props.updateCartValues(response2.data);

      Toastify({
        text: "Your quote has been updated based on your info, the price may have changed.",
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        backgroundColor: "#4a934a",
        stopOnFocus: true,
        className: "notification",
      }).showToast();
      setIsError(false);
    } catch (error) {
      setIsError(true);
      Toastify({
        text:
          error?.response?.data?.detail ||
          "Something went wrong, please try changing your info again.",
        duration: 10000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
      }).showToast();
    }
  };

  const meetPreferredPremiumRequirements = () => {
    if (params.gender == "Male" && props.weight > 240) return false;
    if (params.gender == "Female" && props.weight > 190) return false;
    if (spouse && props.spouse) {
      if (spouse.gender == "Male" && props.spouse.weight > 240) return false;
      if (spouse.gender == "Female" && props.spouse.weight > 190) return false;
    }

    return true;
  };

  const isValid = () => {
    let spouseValid = true;

    if (isError) {
      return true;
    }

    if (spouse) {
      if (
        !props.spouse.first_name ||
        !props.spouse.last_name ||
        !props.spouse.date_of_birth ||
        !props.spouse.feet ||
        !props.spouse.weight
      )
        spouseValid = false;
    }

    let dependentsValid = true;

    dependents.forEach((d) => {
      if (!d.first_name || !d.last_name || !d.date_of_birth) {
        dependentsValid = false;
      }
    });

    if (specialRates && specialRates.prefferedRate === 1) {
      if (!meetPreferredPremiumRequirements()) {
        return true;
      }
    }

    return (
      (!isChildOnlyQuote && !props.weight) ||
      (!isChildOnlyQuote && !props.feet) ||
      !spouseValid ||
      !dependentsValid ||
      !props.address ||
      !props.city ||
      !props.date_of_birth
    );
  };

  console.log(props);

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.additionalInfoContainer}>
      <Container className={css.additionalInfoIconAndTitle}>
        <Container className={css.additionalInfoIcon}>
          <Icon icon="additional-info" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          Enter your personal info:
        </Container>
      </Container>
      <Container className={css.inputs}>
        <Container className={css.applicantContainer}>
          <Container className={css.applicantTitle}>
            <Icon
              fill="#89D8F0"
              className={css.applicantIcon}
              icon="user-circle"
            />{" "}
            {isChildOnlyQuote ? "Child 01" : "Primary Applicant"}:
          </Container>
          <Container>
            <Container>
              <Container className={css.birthdateContainer}>
                <DatePicker
                  disabled
                  name="test-enrollment-enrollment-tools-additionalinfo-input-datebirth"
                  value={
                    typeof props.date_of_birth === "object"
                      ? props.date_of_birth
                      : typeof props.date_of_birth === "string"
                      ? moment(props.date_of_birth)
                      : null
                  }
                  onChange={(e) => {
                    props.setAdditionalInfo({ key: "date_of_birth", value: e });
                  }}
                  before={"Date Of Birth"}
                  focusedlabel="Date of Birth"
                />
              </Container>
              {!isChildOnlyQuote && (
                <InputSlider
                  min={0}
                  max={
                    specialRates.prefferedRate === 1
                      ? params.gender == "Male"
                        ? 240
                        : 190
                      : params.gender == "Male"
                      ? 300
                      : 250
                  }
                  setValue={(v) => {
                    props.setAdditionalInfo({ key: "weight", value: v });
                  }}
                  unit="Lbs"
                  label="Weight:"
                  value={props.weight || 0}
                  icon="weight"
                />
              )}
            </Container>
            {!isChildOnlyQuote && (
              <Container>
                <DoubledInputSlider
                  max={9}
                  setValue={(v) => {
                    props.setAdditionalInfo({
                      key: "feet",
                      value: Math.floor(v),
                    });
                    props.setAdditionalInfo({
                      key: "inches",
                      value: Math.round((v - Math.floor(v)) * 12),
                    });
                  }}
                  setValue1={(v) => {
                    props.setAdditionalInfo({
                      key: "feet",
                      value: Number(v),
                    });
                  }}
                  setValue2={(v) => {
                    props.setAdditionalInfo({
                      key: "inches",
                      value: Number(v),
                    });
                  }}
                  value1={Number(props.feet) || 0}
                  value2={Number(props.inches) || 0}
                  unit="Ft"
                  unit2="Inches"
                  label="Height:"
                  icon="height"
                />
              </Container>
            )}
          </Container>
        </Container>

        {spouse && (
          <Container className={css.applicantContainer}>
            <Container className={css.applicantTitle}>
              <Icon
                fill="#89D8F0"
                className={css.applicantIcon}
                icon="spouse-circle"
              />{" "}
              Spouse:
            </Container>
            <Container>
              <Container>
                <Container className={css.firstNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          first_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.first_name}
                    focusedlabel="Fist Name"
                    placeholder="First Name"
                  />
                </Container>
                <Container className={css.lastNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          last_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.last_name}
                    placeholder="Last Name"
                    focusedlabel="Last Name"
                  />
                </Container>
                <Container className={css.birthdateContainer}>
                  <DatePicker
                    disabled
                    value={
                      typeof props.spouse.date_of_birth === "object"
                        ? props.spouse.date_of_birth
                        : typeof props.spouse.date_of_birth === "string"
                        ? moment(props.spouse.date_of_birth)
                        : null
                    }
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: { ...props.spouse, date_of_birth: e },
                      });
                    }}
                    before={"Date Of Birth"}
                    focusedlabel="Date of Birth"
                  />
                </Container>
                <InputSlider
                  min={0}
                  max={
                    specialRates.prefferedRate === 1
                      ? props.spouse.gender == "Male"
                        ? 240
                        : 190
                      : props.spouse.gender == "Male"
                      ? 300
                      : 250
                  }
                  setValue={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: { ...props.spouse, weight: v },
                    });
                  }}
                  unit="Lbs"
                  label="Weight"
                  value={props.spouse.weight || 0}
                  icon="weight"
                />
              </Container>
              <Container>
                <DoubledInputSlider
                  max={9}
                  setValue={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: {
                        ...props.spouse,
                        feet: Math.floor(v),
                        inches: Math.round((v - Math.floor(v)) * 12),
                      },
                    });
                  }}
                  setValue1={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: {
                        ...props.spouse,
                        feet: v,
                      },
                    });
                  }}
                  setValue2={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: {
                        ...props.spouse,
                        inches: v,
                      },
                    });
                  }}
                  unit="Ft"
                  unit2="Inches"
                  label="Height"
                  value1={props.spouse.feet || 0}
                  value2={props.spouse.inches || 0}
                  icon="height"
                />
              </Container>
            </Container>
          </Container>
        )}
        {dependents.map((dep, ind) => {
          return (
            <Container className={css.applicantContainer}>
              <Container className={css.applicantTitle}>
                <Icon
                  fill="#89D8F0"
                  className={css.applicantIcon}
                  icon="dependent-circle"
                />{" "}
                {isChildOnlyQuote ? "Child" : "Dependent"} {ind + 1}:
              </Container>
              <Container>
                <Container>
                  <Container className={css.firstNameInputContainer}>
                    <TextInput
                      value={dependents[ind].first_name}
                      placeholder="First Name"
                      focusedlabel="First Name"
                      onChange={(e) => {
                        dependents[ind].first_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.lastNameInputContainer}>
                    <TextInput
                      value={dependents[ind].last_name}
                      placeholder="Last Name"
                      focusedlabel="Last Name"
                      onChange={(e) => {
                        dependents[ind].last_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.birthdateContainer}>
                    <DatePicker
                      disabled
                      value={
                        typeof dependents[ind].date_of_birth === "string"
                          ? moment(dependents[ind].date_of_birth)
                          : dependents[ind].date_of_birth
                      }
                      before={"Date Of Birth"}
                      onChange={(e) => {
                        dependents[ind].date_of_birth = e;

                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                        setUpdatedDependentsDateOfBirth((prev) => !prev);
                      }}
                      focusedlabel="Date of Birth"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>
      <Container className={css.lastInputs}>
        <Container className={css.householdContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon fill="#004C64" icon="building" />
            </Container>
            <Container>
              <Container
                style={{ marginLeft: "2px" }}
                className={css.householdTitle}
              >
                Complete Household Address:
              </Container>
            </Container>
          </Container>
          <Container className={css.addressInputs}>
            <Container className={css.streetAndNumberContainer}>
              <Container className={css.addressStreetNameInput}>
                <TextInput
                  name="test-enrollment-enrollment-additionalinfo-input-street-adress"
                  value={props.address}
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "address",
                      value: handleInputChange(e),
                    });
                  }}
                  placeholder="Street Address"
                  focusedlabel="Street Address"
                />
              </Container>
              <Container className={css.addressNumberInput}>
                <TextInput
                  name="test-enrollment-enrollment-additionalinfo-input-apt-unit"
                  placeholder="APT.#, Unit"
                  value={props.apt}
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "apt",
                      value: handleInputChange(e),
                    });
                  }}
                />
              </Container>
            </Container>

            <Container className={css.addressCityInput}>
              <TextInput
                name="test-enrollment-enrollment-additionalinfo-input-city"
                value={props.city}
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "city",
                    value: handleInputChange(e),
                  });
                }}
                placeholder="City"
                focusedlabel="City"
              />
            </Container>
          </Container>
        </Container>
        <Container className={css.coverageContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon fill="#004C64" icon="heart-schedule" />
            </Container>
            <Container
              style={{ marginLeft: "2px" }}
              className={css.householdTitle}
            >
              Select the Coverage Start Date:
            </Container>
          </Container>

          <Container className={css.coverageDates}>
            <Container className={css.coverageDateInput}>
              <DatePicker
                disabledDate={(m) => {
                  const now = moment(new Date());
                  if (now >= m || now.add(effectiveDateLimit, "day") <= m) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                before="Coverage Start Date"
                className={css.datePicker}
                onChange={(v) => {
                  props.setAdditionalInfo({
                    key: "effectiveDate",
                    value: v,
                  });
                  if (!v) return;
                  if (v < moment(firstPaymentDate).add(-1, "day")) {
                    props.setAdditionalInfo({
                      key: "firstPaymentDate",
                      value: moment(v).add(-1, "day"),
                    });
                  }
                }}
                value={effectiveDate}
              />
            </Container>

            <Container className={css.coverageDateInput}>
              <DatePicker
                disabledDate={(m) => {
                  const now = moment(new Date());

                  if (
                    now.add(-1, "day") >= m ||
                    m.isSameOrAfter(moment(effectiveDate), "day")
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                before="First Payment Date"
                className={css.datePicker}
                onChange={(v) => {
                  props.setAdditionalInfo({
                    key: "firstPaymentDate",
                    value: v,
                  });
                }}
                value={firstPaymentDate}
              />
            </Container>

            {/* <Container className={css.coverageDateInput}>
                <TextInput  
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "draftDate",
                      value: handleInputChange(e),
                    }); }}
                  value={draftDate}
                  before="Desired Draft Date"
                /> 
              </Container> */}
          </Container>
        </Container>
      </Container>

      {specialRates.prefferedRate === 1 && !meetPreferredPremiumRequirements() && (
        <Container className={css.weightNotAllowedContainer}>
          <Container className={css.weightNotAllowed}>
            <Container className={css.weightNotAllowedIconAndText}>
              <Container className={css.weightNotAllowedIcon}>
                <Icon
                  style={{ width: "42px", height: "42px" }}
                  icon="information"
                />
              </Container>
              <Container className={css.weightNotAllowedText}>
                Based on the weight entered, you do not qualify for a preferred
                rate. We will continue to enroll at the standard rate.
              </Container>
            </Container>
            <Container>
              <Container className={css.agreeButton}>
                <Button
                  onClick={async () => {
                    let specialRates = {};
                    let selected_term = JSON.parse(
                      localStorage.getItem("additional_info") || "{}"
                    )?.NATIONAL_GENERAL?.default_option;

                    const priorCoverage =
                      sessionStorage.getItem("priorCoverage");
                    const rateTier = sessionStorage.getItem("rateTier");
                    const selectedTermData =
                      sessionStorage.getItem("allstateTermData");

                    if (selectedTermData) {
                      selected_term = JSON.parse(selectedTermData);
                    }

                    if (priorCoverage) {
                      specialRates = {
                        has_prior_coverage:
                          priorCoverage == "true" ? true : false,
                      };
                    }

                    if (rateTier) {
                      specialRates = {
                        ...specialRates,
                        rate_tier: rateTier,
                      };
                    }

                    const response2 = await axios({
                      method: "post",
                      url: `${props.baseUrl}/v1/enrollment/national-general/${params.quote_id}/accurate-quote`,
                      data: {
                        selected_term,
                        zip_code: params.zip_code,
                        state: params.state,
                        effective_date: effectiveDate
                          .toDate()
                          .toISOString()
                          .split("T")[0],
                        primary_applicant: {
                          gender: params.gender,
                          date_of_birth: props.date_of_birth
                            .toISOString()
                            .split("T")[0],
                          uses_tobacco: params.uses_tobacco,
                          ...(Object.keys(specialRates).length > 0
                            ? specialRates
                            : {}),
                        },
                        spouse: props.applicants.spouse
                          ? {
                              ...props.spouse,
                              date_of_birth: props.spouse.date_of_birth
                                .toISOString()
                                .split("T")[0],
                              gender: spouse.gender,
                              uses_tobacco: spouse.uses_tobacco ? true : false,
                              ...(Object.keys(specialRates).length > 0
                                ? specialRates
                                : {}),
                            }
                          : null,
                        dependents: dependents
                          ? dependents.map((d) => {
                              return {
                                ...d,
                                date_of_birth: d.date_of_birth
                                  .toISOString()
                                  .split("T")[0],
                                ...(Object.keys(specialRates).length > 0
                                  ? specialRates
                                  : {}),
                              };
                            })
                          : [],
                        plans: JSON.parse(params.plans).map((p) => {
                          return {
                            plan_key: p.plan_key.value,
                            insurance_network: p.insurance_network.value,
                            fees_enabled: params.optionalAssociationFeeEnabled,
                            secondary_plan_id: p.general_info.secondary_plan_id,
                          };
                        }),
                      },
                      headers: {
                        "x-apollo-quotes-source": props.agentMetadata.id,
                      },
                    });

                    let parsedPlans = response2.data.plans;

                    if (response2.data.discount) {
                      parsedPlans = parsedPlans.map((plan) => {
                        if (
                          plan.plan_id.value === response2.data.discount.plan_id
                        ) {
                          return {
                            ...plan,
                            discount: response2.data.discount,
                          };
                        }
                        return plan;
                      });
                    }

                    localStorage.setItem("plans", JSON.stringify(parsedPlans));
                    props.updateSpecialRates({
                      priorCoverage: specialRates.priorCoverage,
                      prefferedRate: 0,
                    });
                  }}
                  shape="round"
                >
                  I Agree
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      )}

      <Container className={css.priceDisclaimer}>
        *The monthly payment of $
        {JSON.parse(localStorage.getItem("plans")).reduce((a, b) => {
          return a + b.premium.value;
        }, 0)}{" "}
        is consistent with the selected date and term length. <br />
        By setting another date the value will be changed automatically
      </Container>
      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            onClick={() => {
              props.previous && props.previous();
            }}
            type="primary"
            shape="round"
          >
            Previous
          </Button>
        </Container>
        <Container className={css.nextButton}>
          <Button
            disabled={isValid()}
            onClick={() => {
              props.next && props.next();
            }}
            type="primary"
            shape="round"
          >
            Next
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  enrollment,
  applicants,
  specialRates,
  ...rest
}) => {
  return {
    applicants,
    agentMetadata,
    baseUrl,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
    date_of_birth: enrollment.nationalGeneral.additionalInfo.date_of_birth
      ? enrollment.nationalGeneral.additionalInfo.date_of_birth
      : applicants.date_of_birth,
    spouse:
      Object.keys(enrollment.nationalGeneral.additionalInfo.spouse).length > 0
        ? enrollment.nationalGeneral.additionalInfo.spouse
        : applicants.spouse,
    availableTerms: enrollment.nationalGeneral.availableTerms,
    specialRates,
    ...rest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartValues: (payload) => {
      dispatch({ type: `UPDATE_CART_VALUES`, payload });
    },
    updateSpecialRates: (payload) =>
      dispatch({ type: `UPDATE_SPECIAL_RATES`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRaw);
